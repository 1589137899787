<template>
    <strong>Termos e Condições</strong> <p>
            Ao baixar ou usar o aplicativo, estes termos serão
            aplicados automaticamente a você - portanto, certifique-se
            de lê-los atentamente antes de usar o aplicativo. Você não
            tem permissão para copiar ou modificar o aplicativo, qualquer
            parte dele, ou nossas marcas registradas de qualquer forma. Você
            não tem permissão para tentar extrair o código-fonte do aplicativo,
            e também não deve tentar traduzir o aplicativo para outros idiomas ou
            criar versões derivadas. O próprio aplicativo, e todas as marcas registradas,
            direitos autorais, direitos de banco de dados e outros direitos de propriedade intelectual
            relacionados a ele, ainda pertencem a Quiz & Jogos Online.
        </p> <p>
            Quiz & Jogos Online está comprometido em garantir que o aplicativo seja
            o mais útil e eficiente possível. Por essa razão, nos
            reservamos o direito de fazer alterações no aplicativo ou cobrar por
            seus serviços, a qualquer momento e por qualquer motivo. Nós nunca
            iremos cobrar você pelo aplicativo ou seus serviços sem deixar muito
            claro exatamente pelo que você está pagando.
        </p> <p>
            O aplicativo Planos de Leitura da Bíblia armazena e processa dados pessoais que
            você nos forneceu, para fornecer meu
            Serviço. É sua responsabilidade manter seu telefone e
            acesso ao aplicativo seguro. Portanto, recomendamos que você não
            faça jailbreak ou root em seu telefone, que é o processo de
            remoção de restrições e limitações de software impostas pelo
            sistema operacional oficial do seu dispositivo. Isso pode tornar seu
            telefone vulnerável a malware/vírus/programas maliciosos,
            comprometer os recursos de segurança do seu telefone e pode significar
            que o aplicativo Planos de Leitura da Bíblia não funcionará corretamente ou de forma alguma.
        </p> <div><p>
            O aplicativo usa serviços de terceiros que declaram seus
            Termos e Condições.
            </p> <p>
            Link para os Termos e Condições dos provedores de serviços de terceiros
            usados pelo aplicativo
            </p> <ul><li><a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Google Play Services</a></li><li><a href="https://developers.google.com/admob/terms" target="_blank" rel="noopener noreferrer">AdMob</a></li><li><a href="https://www.google.com/analytics/terms/" target="_blank" rel="noopener noreferrer">Google Analytics for Firebase</a></li><li><a href="https://firebase.google.com/terms/crashlytics" target="_blank" rel="noopener noreferrer">Firebase Crashlytics</a></li><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><li><a href="https://onesignal.com/tos" target="_blank" rel="noopener noreferrer">One Signal</a></li><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----></ul></div> <p>
            Você deve estar ciente de que há certas coisas pelas quais
            Quiz & Jogos Online não se responsabilizará. Certas
            funções do aplicativo exigirão que o aplicativo tenha uma conexão
            à internet ativa. A conexão pode ser Wi-Fi ou fornecida
            pelo seu provedor de rede móvel, mas Quiz & Jogos Online
            não pode se responsabilizar pelo aplicativo não funcionar com
            funcionalidade total se você não tiver acesso ao Wi-Fi e não tiver
            nenhum de seus dados de conexão restantes.
        </p> <p></p> <p>
            Se você estiver usando o aplicativo fora de uma área com Wi-Fi, você
            deve lembrar que os termos do acordo com seu
            provedor de rede móvel ainda se aplicarão. Como resultado, você pode
            ser cobrado pelo seu provedor móvel pelo custo dos dados durante
            a conexão ao acessar o aplicativo, ou
            outras cobranças de terceiros. Ao usar o aplicativo, você está
            aceitando a responsabilidade por tais cobranças, incluindo cobranças
            de roaming de dados se você usar o aplicativo fora do seu território
            doméstico (ou seja, região ou país) sem desativar o roaming de dados. Se
            você não for o pagador da conta do dispositivo em que você está
            usando o aplicativo, esteja ciente de que assumimos que você tem
            permissão do pagador da conta para usar o aplicativo.
        </p> <p>
            Da mesma forma, Quiz & Jogos Online nem sempre pode se responsabilizar
            pela forma como você usa o aplicativo, ou seja, você precisa
            garantir que seu dispositivo esteja carregado - se ele ficar sem
            bateria e você não puder ligá-lo para utilizar o Serviço,
            Quiz & Jogos Online não pode aceitar responsabilidade.
        </p> <p>
            Com relação à responsabilidade de Quiz & Jogos Online pelo seu
            uso do aplicativo, quando você está usando o aplicativo, é importante
            ter em mente que, embora nos esforcemos para garantir que esteja
            atualizado e correto o tempo todo, dependemos de terceiros
            para fornecer informações para nós, para que possamos disponibilizá-las
            para você. Quiz & Jogos Online não aceita nenhuma responsabilidade por
            qualquer perda, direta ou indireta, que você possa ter como resultado de
            confiar exclusivamente nesta funcionalidade do aplicativo.
        </p> <p>
            Em algum momento, podemos desejar atualizar o aplicativo. O aplicativo
            está atualmente disponível no Android - os requisitos para o
            sistema (e para quaisquer sistemas adicionais que
            decidirmos estender a disponibilidade do aplicativo) podem mudar,
            e você precisará baixar as atualizações se quiser continuar
            usando o aplicativo. Quiz & Jogos Online não promete que sempre
            atualizará o aplicativo para que seja relevante para você
            e/ou funcione com a versão do Android que você tem
            instalada no seu dispositivo. No entanto, você se compromete a sempre
            aceitar atualizações do aplicativo quando oferecidas a você. Também podemos
            desejar parar de fornecer o aplicativo, e podemos encerrar o uso
            dele a qualquer momento sem aviso prévio a você. A menos que
            digamos o contrário, após qualquer rescisão, (a) os
            direitos e licenças concedidos a você nestes termos terminarão;
            (b) você deve parar de usar o aplicativo e (se necessário) excluí-lo
            do seu dispositivo.
        </p> <p><strong>Alterações a Este Termos e Condições</strong></p> <p>
            Eu posso atualizar nossos Termos e Condições
            de tempos em tempos. Assim, você é aconselhado a revisar esta página
            periodicamente para quaisquer alterações. Eu irei
            notificá-lo de quaisquer alterações publicando os novos Termos e
            Condições nesta página.
        </p> <p>
            Estes termos e condições são eficazes a partir de 2024-02-22
        </p> <p><strong>Contate-Nos</strong></p> <p>
            Se você tiver alguma dúvida ou sugestão sobre meus
            Termos e Condições, não hesite em me contatar
            em quizejogosonline@gmail.com.
        </p>

</template>

<script>
export default {
    name: "LeituraBiblicaDiariaPrivacyPolicy"
}
</script>